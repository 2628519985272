import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { apiURL } from '../Commen/apiurl';
import axios from 'axios';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaSearch, FaMapMarkerAlt } from 'react-icons/fa';
import '../CommunityAdd/Eventlistpage.css';
import Imagespic from '../Images/Imagespic';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const localizer = momentLocalizer(moment);

function EventCalendar() {
    const [events, setEvents] = useState([]);
    const [tooltipEvent, setTooltipEvent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const calendarRef = useRef(null);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`${apiURL}/UserMaster/get_all_events`)
            .then((response) => {
                const eventData = response.data.data;
                setEvents(eventData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    }, []);

    const fetchSearchEventDetails = () => {
        setIsLoading(true);
        axios
            .get(`${apiURL}/UserMaster/event_search_data?search_query=${search}`, {
                headers: {
                    accept: "application/json",
                },
            })
            .then((response) => {
                const eventData = response.data.data;
                setEvents(eventData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };
    const [hoveredButton, setHoveredButton] = useState(null);
    const handleMouseEnter = (event, e) => {
        const buttonId = event.id;

        // Get the button's position relative to the viewport
        const buttonRect = e.target.getBoundingClientRect();

        // Set the hovered button and tooltip position
        setHoveredButton(buttonId);
        setTooltipPosition({
            top: buttonRect.top,//+ window.scrollY,
            left: buttonRect.left + window.scrollX + buttonRect.width / 2,
        });
        // Set the tooltip event
        setTooltipEvent(event);

        // Show the tooltip
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        // Clear the hovered button and hide the tooltip
        setTooltipVisible(false);
        setHoveredButton(null);
        setTooltipEvent(null);
        setTooltipPosition({ top: 0, left: 0 });
    };

    const [navbarClass, setNavbarClass] = useState('scrollbanner_height');
    const [hasNavScrolledtext, sethasNavScrolledtext] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [scrollY1, setScrollY1] = useState(-25);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScrollY(window.scrollY);
        setScrollY1(window.scrollY1);

        if (window.scrollY > 100) {
            setNavbarClass('navbar-yellow');
            sethasNavScrolledtext(true);
            handleMouseLeave()
        } else {
            setNavbarClass('scrollbanner_height');
            sethasNavScrolledtext(false);
        }
    };

    // const handleMouseLeave = () => {
    //     setTooltipEvent(null);
    //     setTooltipVisible(false);
    // };

    const customEvent = ({ event }) => {
        const eventId = String(event.id);
        const encodedId = window.btoa(eventId);
        const handleEventClick = () => {
            window.location.href = `/EventIndividual/${encodedId}`;
        };
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-${event.id}`}>
                        <div>
                            <h4>{event.event_title}</h4>
                        </div>
                    </Tooltip>
                }
            >
                <div
                    onClick={handleEventClick}
                    onMouseEnter={(e) => handleMouseEnter(event, e)}
                    onMouseLeave={handleMouseLeave}
                    className={event.event_start_date === event.event_end_date ? 'backdiv calendar-event' : 'calendar-event'}
                    style={{
                        overflow: 'visible',
                        zIndex: 1,
                    }}
                >
                    <h4>{event.event_title}</h4>
                </div>
            </OverlayTrigger>
        );
    };

    const handleCloseTooltip = () => {
        setTooltipVisible(false);
    };

    const imageConvert = (e) => {
        let image1 = JSON.parse(e);
        console.log('image1', image1);
        return 'data:image;base64,' + image1.base64Data
    }
    function formatDate(dateString) {
        if (!dateString) return '';

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    }
    const customDate = (startdate, enddate) => {
        if (startdate == enddate) {
            return formatDate(startdate)
        }
        else {
            return formatDate(startdate) - formatDate(enddate)
        }
    }
    const customTooltip = () => {
        if (!tooltipEvent || !tooltipVisible) return null;

        const converted_image = tooltipEvent.event_image && tooltipEvent.event_image != '""'
            ? imageConvert(tooltipEvent.event_image)
            : Imagespic.Frameicon;
        let tooltipStyle = {
            position: 'fixed',
            top: `${tooltipPosition.top}px`,
            transform: 'translateX(-50%)',
            backgroundColor: '#fff',
            padding: '10px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            zIndex: 1000,
            visibility: hoveredButton ? 'visible' : 'hidden',
        };
        const mediaQuery = window.matchMedia('(max-width: 600px)');
        if (mediaQuery.matches) {
            if (tooltipPosition.left > 150) {
                tooltipStyle = {
                    ...tooltipStyle,
                    left: `${tooltipPosition.left - 50 }px`,
                };
            } else {
                tooltipStyle = {
                    ...tooltipStyle,
                    left: `${tooltipPosition.left + 100}px`,
                };
            }
        }
        else{
            tooltipStyle = {
                    ...tooltipStyle,
                    left: `${tooltipPosition.left}px`,
                };
        }
        const imageStyle = {
            maxWidth: '100%',
            // height: '200px',
        };
        const startTime = moment(tooltipEvent.start);
        const endTime = moment(tooltipEvent.end);
        const formattedStartTime = startTime.format('h:mm A');
        const formattedEndTime = endTime.format('h:mm A');
        const eventId = String(tooltipEvent.id);
        const encodedId = window.btoa(eventId);
        return (
            <div className="custom-tooltip" style={tooltipStyle} onMouseLeave={handleMouseLeave}>
                <button onClick={handleCloseTooltip} className="close-button closeicon ml-2 mb-2">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <Link to={`/EventIndividual/${encodedId}`}
                    state={{ ID: tooltipEvent.id }} style={{ textDecoration: 'none', display: 'inline-block' }} key={tooltipEvent.id}>
                    {converted_image && (
                        <img
                            src={converted_image}
                            alt={tooltipEvent.event_title}
                            style={imageStyle}
                        />
                    )}
                    <h1 style={{ color: "black", maxWidth: "70%" }}>{tooltipEvent.event_title}</h1>
                    {tooltipEvent.link && (
                        <h3>{tooltipEvent.link}</h3>
                    )}
                    {tooltipEvent.venue_details && (
                        <h3 style={{ color: "black" }}>{tooltipEvent.venue_details}</h3>
                    )}
                    {/* <h4 style={{ color: "black" }}>{tooltipEvent.event_start_date}-{tooltipEvent.event_start_date}</h4> */}
                    <h4 style={{ color: "black" }}>{tooltipEvent.event_start_date === tooltipEvent.event_end_date
                        ? formatDate(tooltipEvent.event_start_date)
                        : `${formatDate(tooltipEvent.event_start_date)} - ${formatDate(tooltipEvent.event_end_date)}`}</h4>
                    <div>
                        <h4 style={{ color: "black" }}>{formattedStartTime} - {formattedEndTime}</h4>
                    </div>
                </Link>
            </div>
        );
    };

    const CustomDayEvent = ({ event }) => {
        const eventId = String(event.id);
        const encodedId = window.btoa(eventId);
        const handleEventClick = () => {
            window.location.href = `/EventIndividual/${encodedId}`;
        };
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-${event.id}`}>
                        <div>
                            <h4>{event.event_title}</h4>
                        </div>
                    </Tooltip>
                }
            >
                <div
                    onClick={handleEventClick}
                    onMouseEnter={(e) => handleMouseEnter(event, e)}
                    onMouseLeave={handleMouseLeave}
                    className="calendar-event"
                    style={{
                        overflow: 'visible',
                        zIndex: 1,
                    }}
                >
                    <h4>{event.event_title}</h4>
                </div>
            </OverlayTrigger>
        );
    };
    const customEventStyleGetter = (event, start, end, isSelected) => {

        const style = {
            backgroundColor: '#3a87ad',
            borderColor: '#285e8e',
        };

        if (moment(start).isSame(moment(), 'day')) {
            style.backgroundColor = '#ffcccb';
        }

        if (moment(start).isSame(moment(), 'week')) {
            style.backgroundColor = '#e6e6e6';
        }

        return {
            style,
        };
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            fetchSearchEventDetails();
        }
    };


    return (
        <div className='addformfullcss'>
            <Header></Header>
            <div className="event_parallax" style={{ transform: `translateY(${scrollY1}px)`, }}>
                <div className='parallax-content'>
                    <h1 className='text-center font-weight-bold hongkong-font mobile-top oppotunity_banner_text' style={{ fontSize: '40px', fontWeight: '400 !important' }}>Events</h1>
                </div>
            </div>
            <div className='container'>
                <div class="mt-5 cd_breadcrumbs_container mb-5">
                    <span>
                        <span><a href="https://www.creativedignity.org/">Home</a>
                        </span> &gt; <span class="breadcrumb_last" aria-current="page"><a href='/event'>Events</a></span>
                        &gt; <span class="breadcrumb_last" aria-current="page">Event Calender</span>
                    </span>
                </div>
                <p className='texbannerevent1' >Handmade events are an immersive journey into a world of craftsmanship, culture, and creativity. Some are sensory delights where vibrant colors, intricate textures, and diverse traditions come to life, some connect us to the roots of our knowledge and lifestyle and some make us hope for a conscious future. </p>

                <div className='row'>
                    <div className='col-6'>
                    </div>
                    <div class='col-xl-6 col-lg-6 col-md-6 mb-3 px-2'>
                        <Link to="/Eventform" state={{ ID: 0, Flag: "I" }}>
                            <Button variant="secondary" className='btnaddoppo'>
                                Add Event</Button>
                        </Link>
                    </div>
                </div>
                <div className='searchevent'>
                    <FaSearch className="search-icon1" />
                    <input type='text' className='inptsearch' placeholder='Search for events' onKeyPress={handleKeyPress} value={search} onChange={(e) => setSearch(e.target.value)}></input>
                    <button className='btnfinevent' onClick={fetchSearchEventDetails}>Find Events</button>
                    <a class="inline-p  list_a" style={{ textDecoration: 'none' }} href="/event">List</a>
                    <a class="inline-p" style={{ textDecoration: 'underline' }} >Calendar </a>
                </div>



                <div className="event-calendar-container mt-5">
                    <Calendar
                        ref={calendarRef}
                        localizer={localizer}
                        events={events.map((event) => ({
                            ...event,
                            start: new Date(event.event_start_date + 'T' + event.event_start_time),
                            end: new Date(event.event_end_date + 'T' + event.event_end_time),
                        }))}
                        startAccessor={(event) => new Date(event.start)}
                        endAccessor={(event) => new Date(event.end)}
                        style={{ height: 700 }}
                        components={{
                            event: customEvent,
                            day: {
                                event: CustomDayEvent,
                            },
                        }}
                        views={['month', 'week', 'day']}
                        eventPropGetter={customEventStyleGetter}
                    />
                    {customTooltip()}
                </div>
                {isLoading && (
                    <div className="spinner-box">
                        <div className="pulse-container">
                            <div className="pulse-bubble pulse-bubble-1"></div>
                            <div className="pulse-bubble pulse-bubble-2"></div>
                            <div className="pulse-bubble pulse-bubble-3"></div>
                        </div>
                    </div>
                )}
            </div>
            <div className='mt-5'>
                <Footer />
            </div>
        </div>
    );
}

export default EventCalendar;
