import React from 'react'
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <div>
            <div class="footer ">
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-4 col-sm-4 col-xs-12 pl-5" style={{ borderRight: "1px solid white", paddingRight: "25px" }}>
                            <div class="single_footer">
                                <h6 className='footerh6_1 mb-5'>Creative Dignity</h6>
                                <p class="footerh6_2" >A pandemic-born movement collectivizing the efforts in the Indian handmade sector enabling artisans to be equal and active participants in building a thriving ecosystem of the future that brings well-being for the planet and people.</p>
                                <div className='row mt-4'>
                                    <div className='col-md-4 '>
                                        <h6 className='footerh6_2'>© 2023 — 2024</h6>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-md-8 col-sm-4 col-xs-12  pl-5" >
                            <div className='container-fluid row ' >
                                <div class="col-md-2 col-sm-4 col-xs-12  ">
                                    <div class="single_footer single_footer_address ">
                                        <h6 className='footerh6 mb-5'>About us</h6 >
                                        <ul>
                                            <li class=''><a className='' href='https://www.creativedignity.org/about-us/'>Our story</a></li>
                                            <li class=''><a className='' href="https://www.creativedignity.org/people/">People</a></li>
                                            <li class=''>
                                                <a className='' style={{textWrap:'wrap'}}
                                                    href="https://www.creativedignity.org/collaborators-supporters/" >
                                                    Collaborators &amp; supporters</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-12">
                                    <div class="single_footer single_footer_address">
                                        <h6 className='footerh6 mb-5'>Network</h6 >
                                        <ul>
                                            <li class=''>  <a className='footer_decoration footer_a_tag coll-next '  href='/artisan' >Artisan directory</a></li>
                                            <li class=''><a className='' href='/member'>Members</a></li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-12 ">
                                    <div class="single_footer single_footer_address">
                                        <h6 className='footerh6 mb-5'>What's on</h6 >
                                        <ul>
                                            <li class=''><a className='' href='https://www.creativedignity.org/programme/'>Programs</a></li>
                                            <li class=''><a className='' href='/event'>Events</a></li>
                                            <li class='' ><a className='' href='/opportunities'>Opportunities</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-12">
                                    <div class="single_footer single_footer_address">
                                        <h6 className='footerh6 mb-5'>Media</h6 >
                                        <ul>
                                            <li class=''><a className='footer_decoration' href="https://www.creativedignity.org/instagram/">Instagram</a></li>
                                            <li class=''><a className='footer_decoration' href="https://www.creativedignity.org/blog/">Blog</a></li>

                                            <li className='' >
                                                <Link to='https://www.creativedignity.org/interesting-read/' state={{ ID: 0, Flag: 'I' }} className='' style={{textWrap:'wrap'}}>
                                                    Interesting reads
                                                </Link>
                                            </li>


                                            <li class=''><a className='' href='https://www.creativedignity.org/press/'>CD in the press</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-12">
                                    <div class="single_footer single_footer_address">
                                        <h6 className='footerh6 mb-5'>Get involved</h6 >
                                        <ul>
                                            <li class=''><a className='' href="/Volunteerform">Volunteer</a></li>
                                            <li class='' > <a className=''  href='/Member_form' >Become a member</a></li>
                                            <li class=''><a className='' href='https://pages.razorpay.com/pl_IDWr28ZHUL1f07/view'>Donate</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-4 col-xs-12">
                                    <div class="single_footer single_footer_address footer-padding">
                                        <h6 className='footerh6 mb-5'>More</h6 >
                                        <ul>
                                            <li class=''><a className='' href='https://www.creativedignity.org/resources/'>Resources</a></li>
                                            <li class=''><a className=''  href='https://www.creativedignity.org/contact-us/'>Contact us</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer