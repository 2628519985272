import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './artisan_form.css'

function Formone(props) {

  const { arisandata, uploadedImages, uploadlogodrop, setUploadedImages, setselectedmaterials, selectedmaterials, inputKey, inputRef, selectedCategory, setSelectedCategory, handleChange, next, uploadlogo, uploadlogotwo, artisanformData, error, setError,
    handleDescriptionChange, handleCategoryChange, artisaneditid, subcraft, acceptPracticedOneOptions, craftCategories, craftNames, handlesubcaraft, handleMaterialsChange, materialCategories, handlesubmaterial, submaterial, materialNames, craftOther, materialOther, artisanlogo, artisanlogotwo } = props;
  const [yourlogo, setYourLogo] = useState("");
  const [portfolio, setPortFolio] = useState("")
  const [catalogue, setCatalogue] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [descriptionInputs, setDescriptionInputs] = useState([]);
  const [logo, setLogo] = useState(null);

  const [craftCategoryId, setCraftCategoryId] = useState('');
  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');

  const nextData = () => {
    const requiredAreYouValues = [
      'Social enterprise',
      'SHG',
      'Producer company',
      'NGO',
      'Co-operatives/society',
      'Social Enterprise'
    ];

    if (requiredAreYouValues.includes(arisandata.areyouval)) {
      if (arisandata.orginizationval) {

        next();

      } else {
        toast.error('Please Enter Organization Name');
      }
    } else if (arisandata.areyouval === 'Individual artisan / artisan family business') {
      if (arisandata.orginizationval) {

        next();
      }
      else {
        toast.error('Please enter yourname');
      }

    } else if (arisandata.areyouval) {
      if (arisandata.orginizationval) {
        next();
      } else {
        toast.error('Please Enter Organization Name');
      }
    } else {
      toast.error('Please Choose Are you');
    }
  }



  const handlesChanges = (event) => {

  };



  const [acceptmaterialsOneOptions, setAcceptmaterialsOneOptions] = useState([]);


  const handleDeleteClick = (index) => {
    const newPreviews = [...uploadedImages];
    newPreviews.splice(index, 1);
    setUploadedImages(newPreviews);
  };

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
  }

  const uploaImage = (e) => {
    return 'data:image;base64,' + e.base64Data
  }

  return (
    <div className='container mt-5 formonecontain'>
      <ToastContainer toastOptions={{ position: "top-right" }} />
      <div className='col-12 row selectwrapper'>
        <label className='label-head areumrg'>Are you a <span className='required-color'> (required)</span></label>
        <select className='artisanarudrp' value={arisandata.areyouval} onChange={handleChange} name='areyouval'>
          <option value=''>--Select--</option>
          <option>Co-operatives/society</option>
          <option>Individual artisan / artisan family business</option>
          <option>NGO</option>
          <option>Producer company</option>
          <option>SHG</option>
          <option>Social enterprise</option>
        </select>
      </div>
      {(arisandata.areyouval === 'Social enterprise' || arisandata.areyouval === 'SHG' || arisandata.areyouval === 'Producer company' || arisandata.areyouval === 'NGO' || arisandata.areyouval === 'Co-operatives/society' || arisandata.areyouval === 'Social Enterprise') &&
        <>
          <div className='boobbbb col-12 row' >
            <h3 className=' gsection_title mt-4'>Biography</h3>
          </div>

          <div className='col-12 row mt-3'>
            <label className='label-head'>Organization name <span className='required-color'> (required)</span></label>
            <input type='text' className='form-control' value={arisandata.orginizationval} name='orginizationval' onChange={handleChange} placeholder='Organization Name' />
          </div>

          <div className="col-12 row mt-3">
            <label className="label-head">Your logo / photo of the team or makers</label>
            <input type="file" uploaImage className="inputlogo file-change" onChange={(e) => uploadlogo(e, 'logo')} />
            {/* {logo && (
              <div>
                <img src={logo} alt="Uploaded Logo" style={{ maxWidth: '100px' }} />
              </div>
            )} */}
            {artisanlogo ? (
              <div className='col-md-4'>
                <img
                  alt='No File Chosen'
                  className='imgvieimageone member_image ml-4'
                  // src={logo} 
                  src={uploaImage(artisanlogo)}

                />
              </div>

            ) : (
              <p className='' style={{ fontSize: "20px" }}>No file chosen</p>
            )}
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Bio - about the organization</label>
            <textarea type='text' className='text-area mb-3' placeholder='Write a little about the organization, its vision and speciality, the founders and the team, no. of artisans the organization works with, products made, etc' row='10' col='10' value={arisandata.bioorginization} name='bioorginization' onChange={handleChange}></textarea>

          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>About the craft</label>
            <textarea type='text' className='text-area mb-3' placeholder='Write a little about the craft, its speciality, its history, processes involved, raw materials, etc.' row='10' col='10' value={arisandata.aboutthecraft} name='aboutthecraft' onChange={handleChange}></textarea>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Portfolio images</label>
            <div className='upload-drop'>
              <p className='drop-text'>Drop files here or</p>
              <div className='fileimage'>
                <div className='image-color'><span className='choseimg'>Choose image</span>
                  <input type='file' key={inputKey} ref={inputRef} className='form-control uploadf' onChange={(e) => uploadlogodrop(e, 'logodrop')} multiple />
                </div>
              </div>
            </div>
            {uploadedImages.map((image, index) => (
              <div key={index} className='image-container'>
                <img src={image.base64} alt={`Thumbnail ${index}`} className='thumbnail mt-5' />
                <div className='image-description'>
                  <textarea type='text'
                    className='text-area custom-textarea mb-4 thumbnail-mobile mt-4'
                    placeholder='Enter the description'
                    rows='71' cols='60' style={{ height: "71px", fontFamily: "Roboto" }}
                    value={image.description}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  />
                </div>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='img-delete-icon'
                  onClick={() => handleDeleteClick(index)}
                  size="2x"
                />
              </div>
            ))}
            <p className='para-change'>Accepted file types: jpg, jpeg, png, Max. file size: 32 MB, Max. files: 5.</p>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Catalogue PDF</label>
            <input type='file' accept="application/pdf" className='inputlogo file-change' onChange={(e) => uploadlogotwo(e, 'logotwo')} />


            {artisanlogotwo ? (
              <p className='' style={{ fontSize: "20px" }}>{artisanlogotwo.fileName}</p>

            ) : (
              <p className='' style={{ fontSize: "20px" }}>No file Choose</p>
            )}
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Craft practised under the category  </label>
          </div>
          <div className='col-12 row selectwrapperfour'>
            <div className='col-6  justify-content-between'>
              <select
                class='form-control foreventorgani'
                value={selectedCategory}
                name='acceptpracticed'
                onChange={handleCategoryChange}
              >
                <option value="" className='crafcate'>--Select craft category--</option>
                {craftCategories && craftCategories.map(category => (

                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-6' >
              <select
                class='form-control foreventorgani'
                value={subcraft}
                name='acceptpracticedone'
                onChange={handlesubcaraft}
              >
                <option className=''>--Select craft name--</option>
                {craftNames && craftNames.map(subcraft => (
                  <option key={subcraft.name} value={subcraft.name}>
                    {subcraft.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='mt-4 craftcss'>
              {subcraft === 'Others' && (
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter craft name'
                  value={craftOther}
                  name='craftother'
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Materials used for the craft </label>
          </div>
          <div className='col-12 row selectwrapperfour'>
            <div className='col-6 d-flex justify-content-between' >
              <select
                class='form-control foreventorgani'
                value={selectedmaterials}
                name='selectedmaterials'
                onChange={handleMaterialsChange}
              >
                <option value="" className='crafcate'>--Select material category--</option>
                {materialCategories && materialCategories.map(material => (
                  <option key={material.name} value={material.name}>
                    {material.name}
                  </option>
                ))}
              </select>
            </div>
            <div class='col-6' >
              <select
                class='form-control foreventorgani'
                value={submaterial}
                name='materialsuseone'
                onChange={handlesubmaterial}
              >
                <option className=''>--Select material name--</option>
                {materialNames && materialNames.map(material => (
                  <option key={material.name} value={material.name}>
                    {material.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='mt-4  craftcss'>
              {submaterial === 'Others' && (
                <input
                  type='text'
                  class='form-control foreventorgani'
                  placeholder='Enter material name'
                  value={materialOther}
                  name='materialother'
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
          <div className='col-12 row mt-3 productmrg'>
            <label className='label-head '>Product range</label>

            <div className='mt-4  craftcss'>
              <input type='text' class='form-control foreventorgani' value={arisandata.productrange} name='productrange' onChange={handleChange} />
            </div>
          </div>
          <div className='mt-5 mb-5'>
            <button
              onClick={nextData}
              className="bg-gradient-secondary-firstnext"
            >
              Next
            </button>
          </div>
        </>
      }
      {arisandata.areyouval === 'Individual artisan / artisan family business' &&
        <>
          <div className='boobbbb col-12 row' >
            <h3 className=' gsection_title mt-4'>Biography</h3>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Your name <span className='required-color'> (required)</span></label>
            <input type='text' className='form-control' value={arisandata.orginizationval} name='orginizationval' onChange={handleChange} placeholder='Your Name' />
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Brand name <span className='required-color'></span></label>
            <input type='text' className='form-control' value={arisandata.brandname} name='brandname' onChange={handleChange} placeholder='Brand Name' />
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'> Your photo </label>
            <input type='file' className='inputlogo file-change' onChange={(e) => uploadlogo(e, 'logo')} />
            {artisanlogo ? (
              <div className='col-md-4'>
                <img
                  alt='No File Chosen'
                  className='imgvieimageone member_image ml-4'
                  // src={logo} 
                  src={uploaImage(artisanlogo)}

                />
              </div>

            ) : (
              <p className='' style={{ fontSize: "20px" }}>No file chosen</p>
            )}
          </div>

          <div className='col-12 row mt-3'>
            <label className='label-head'>Bio - about you     </label>
            <textarea type='text' className='text-area mb-3' row='10' placeholder='Write a little about the organization, its vision and speciality, the founders and the team, no. of artisans the organization works with, products made, etc' col='10' value={arisandata.bioorginization} name='bioorginization' onChange={handleChange}></textarea>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>About the craft</label>
            <textarea type='text' placeholder='Write a little about the craft, its speciality, its history, processes involved, raw materials, etc.' className='text-area mb-3' row='10' col='10' value={arisandata.aboutthecraft} name='aboutthecraft' onChange={handleChange}></textarea>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Portfolio images</label>
            <div className='upload-drop'>
              <p className='drop-text'>Drop files here or</p>
              <div className='fileimage'>
                <div className='image-color'><span className='choseimg'>Choose image</span>
                  <input type='file' key={inputKey} ref={inputRef} className='form-control uploadf' onChange={(e) => uploadlogodrop(e, 'logodrop')} multiple />
                </div>
              </div>
            </div>
            {uploadedImages.map((image, index) => (
              <div key={index} className='image-container'>
                <img src={image.base64} alt={`Thumbnail ${index}`} className='thumbnail mt-5' />
                <div className='image-description'>
                  <textarea type='text'
                    className='text-area custom-textarea mb-4 thumbnail-mobile mt-4'
                    placeholder='Enter the description'
                    rows='71' cols='60' style={{ height: "71px", fontFamily: "Roboto" }}
                    value={image.description}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                  />
                </div>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='img-delete-icon'
                  onClick={() => handleDeleteClick(index)}
                  size="2x"
                />
              </div>
            ))}
            <p className='para-change'>Accepted file types: jpg, jpeg, png, Max. file size: 32 MB, Max. files: 5.</p>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Catalogue PDF</label>
            <input type='file' accept="application/pdf" className='inputlogo file-change' onChange={(e) => uploadlogotwo(e, 'logotwo')} />


            {artisanlogotwo ? (
              // <p className='' style={{ fontSize: "20px" }}>{artisanlogotwo.fileName ? artisanlogotwo.fileName : `Artisan_pdf ${artisaneditid}.pdf`}</p>
              <p className='' style={{ fontSize: "20px" }}>{`Artisan_pdf ${artisaneditid}.pdf`}</p>
            ) : (
              <p className='' style={{ fontSize: "20px" }}>No file Chosen</p>
            )}
          </div>

          <div className='col-12 row mt-3'>
            <label className='label-head'>Craft practised under the category </label>
          </div>
          <div className='col-12 row selectwrapperfour'>
            <div className='col-6  justify-content-between'>
              <select
                class='form-control foreventorgani'
                value={selectedCategory}
                name='acceptpracticed'
                onChange={handleCategoryChange}
              >
                <option value="" className='crafcate'>--Select craft category--</option>
                {craftCategories && craftCategories.map(category => (

                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-6' >
              <select
                class='form-control foreventorgani'
                value={subcraft}
                name='acceptpracticedone'
                onChange={handlesubcaraft}
              >
                <option value='' className=''>--Select craft name--</option>
                {craftNames && craftNames.map(subcraft => (
                  <option key={subcraft.name} value={subcraft.name}>
                    {subcraft.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='mt-4 craftcss'>
              {subcraft === 'Others' && (
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter craft name'
                  value={craftOther}
                  name='craftother'
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
          <div className='col-12 row mt-3'>
            <label className='label-head'>Materials used for the craft
            </label>
          </div>
          <div className='col-12 row selectwrapperfour'>
            <div className='col-6 d-flex justify-content-between' >
              <select
                class='form-control foreventorgani'
                value={selectedmaterials}
                name='selectedmaterials'
                onChange={handleMaterialsChange}
              >
                <option value="" className='crafcate'>--Select material category--</option>
                {materialCategories && materialCategories.map(material => (
                  <option key={material.name} value={material.name}>
                    {material.name}
                  </option>
                ))}
              </select>
            </div>
            <div class='col-6' >
              <select
                class='form-control foreventorgani'
                value={submaterial}
                name='materialsuseone'
                onChange={handlesubmaterial}
              >
                <option value='' className='crafcate'>--Select material name--</option>
                {materialNames && materialNames.map(material => (
                  <option key={material.name} value={material.name}>
                    {material.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='mt-4  craftcss'>
              {submaterial === 'Others' && (
                <input
                  type='text'
                  class='form-control foreventorgani'
                  placeholder='Enter material name'
                  value={materialOther}
                  name='materialother'
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
          <div className='col-12 row mt-3 productmrg'>
            <label className='label-head '>Product range</label>

            <div className='mt-4  craftcss'>
              <input type='text' class='form-control foreventorgani' value={arisandata.productrange} name='productrange' onChange={handleChange} />
            </div>
          </div>
          <div className='mt-5 mb-5'>
            <button
              onClick={nextData}
              className="bg-gradient-secondary-firstnext px-3"
            >
              Next
            </button>
          </div>
        </>}
    </div >
  )
}

export default Formone