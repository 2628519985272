import React from 'react'
import Header from '../Header/header';
import Footer from '../Footer/footer';
import { ToastContainer, toast } from 'react-toastify';
import './Addopportunitycs.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiURL } from "../Commen/apiurl"
import Swal from 'sweetalert2';
import Individual_Header from '../Header/new_header';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router';
import ReactGoogleReCaptcha from "react-google-recaptcha";

function Addopportunityform() {
    const location = useLocation();
    let id = 0;
    let Flag = 'I'
    try {
      const { ID, Flag } = location.state;
      id = ID;
      Flag = Flag
  
    } catch (ex) { }
    const navigate = useNavigate()
    const [OpportunityMode, setOpportunityMode] = useState("")
    const [link, setLink] = useState("")
    const [platform_name, setPlatform_name] = useState("")
    const [opporvenuedetails, setopporvenuedetails] = useState("")
    const [opporvenuedetailshyper, setOpporvenuedetailsHyper] = useState("")
    const [title, setTitle] = useState("")
    const [oppertunitytype, setOppertunitytype] = useState("")
    const [oppertunitytypeoption, setOppertunitytypeoption] = useState("")
    const [jobdescription, setJobdescription] = useState("")
    const [closingdate, setClosingdate] = useState("")
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinked] = useState("")
    const [twitter, setTwitter] = useState("")
    const [response, setResponse] = useState(null)
    const [logo, setLogo] = useState("")
    const [cdExclusive, setCdExclusive] = useState("")
    const [event_banner_image, setOpportunityImage] = useState(null);
    const [emplojob, setEmplojob] = useState([]);
    const [tags, setTags] = useState("");
    const [oppoimage, setOppoimage] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setAPIResponse] = useState("");
    const [token, setToken] = useState("");
    const [captacha, setcaptacha] = useState(false);
    const { quill, quillRef } = useQuill();
    useEffect(() => {
        if (id != 0) {
            getData()
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
    }, []);
    const handleVerify = (token) => {
        setToken(token);

    };
    const handleChange = (value) => {
        setcaptacha(value)
    }
    const postData = async () => {
        if (title) {
            if (OpportunityMode) {
                if (jobdescription) {
                    if (captacha == true) {
                        setIsLoading(true);
                        try {
                            const response = await axios.post(`${apiURL}/UserMaster/create_opportunity`, {
                                "id": id,
                                "tittle": title,
                                "company_logo": JSON.stringify(logo),
                                "Opportunity_type": oppertunitytype,
                                "location": "location",
                                "job_description": jobdescription,
                                "closing_date": closingdate,
                                "instagram_url": instagram,
                                "facebook_url": facebook,
                                "linkedin_url": linkedin,
                                "twitter_url": twitter,
                                "cd_member": cdExclusive,
                                "banner_image": "",
                                "platform_name": platform_name,
                                "url": link,
                                "venue": opporvenuedetails,
                                "opportunity_mode": OpportunityMode,
                                "tags": tags,
                                "banner_image": "banner",
                                "opportunity_image": JSON.stringify(oppoimage),
                                "employment_type": JSON.stringify(emplojob),
                                "Flag": Flag,
                            });
                            setIsLoading(false);
                            if (Flag == "U") {
                                showAlert1();
                                navigate('/Admin_approval')
                            } else {
                                showAlert();
                                navigate('/opportunities')
                            }
                            setResponse(response.data);
                        } catch (error) {
                            console.error('Error making POST request:', error);
                            setIsLoading(false);
                        }
                    } else {
                        toast.error('Please verify the CAPTCHA');
                    }
                } else {
                    toast.error('Please Enter Description')
                }
            } else {
                toast.error('Please Choose Opportunity mode')
            }
        } else {
            toast.error('Please Enter Title name')
        }
    };
    const showAlert = () => {
        Swal.fire({
            title: 'Thank you for sharing an interesting opportunity, it will be added to our What’s On section soon!',
            confirmButtonText: 'OK',
            confirmButtonColor: 'transparent',
            customClass: {
                confirmButton: 'red-background black-font',
            },
            buttonsStyling: false,
        });
    };
    const showAlert1 = () => {
        Swal.fire({
            title: 'Updated Successfully',
            confirmButtonText: 'OK',
            confirmButtonColor: 'transparent',
            customClass: {
                confirmButton: 'red-background black-font',
            },
            buttonsStyling: false,
        });

    };
    const handleChangeempjob = (event) => {
        const valueToAddOrRemove = event.target.value;
        if (emplojob.includes(valueToAddOrRemove)) {
            const newArray = emplojob.filter(day => day !== valueToAddOrRemove);
            setEmplojob(newArray);
        } else {
            const newArray = [...emplojob, valueToAddOrRemove];
            setEmplojob(newArray);
        }
    }
    const uploadImage = (e, flag) => {

        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handleReaderLoaded.bind(this, flag);
            reader.readAsBinaryString(file);
        }
    };
    const handleReaderLoaded = (flag, e) => {

        let binaryString = e.target.result;
        if (flag == 'logo') {
            setLogo({
                base64Data: btoa(binaryString),
            });
        }
    };
    const handleCD_Exclusive = (e) => {
        setCdExclusive(e.target.value);
    };
    const handleOpportunityModeChange = (e) => {
        setOpportunityMode(e.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(jobdescription);

            quill.on('text-change', () => {
                setJobdescription(quillRef.current.firstChild.innerHTML);
            });
        }
    }, [quill]);
    const uploadopportImage = (e, flag) => {
        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handleReaderLoadedone.bind(this, flag);
            reader.readAsBinaryString(file);
        }
    }
    const handleReaderLoadedone = (flag, e) => {
        let binaryString = e.target.result;
        if (flag == 'oppoimage') {
            setOppoimage({
                base64Data: btoa(binaryString),
            });
        }
    };
    const getData = async () => {
      
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiURL}/Get_all_module/get_by_id?id=${id}&Flag=Opportunity`);
            setAPIResponse(response.data.data)
            setTitle(response.data.data.tittle)
            setTags(response.data.data.tags)
            setLogo(JSON.parse(response.data.data.company_logo))
            setOppoimage(JSON.parse(response.data.data.opportunity_image))
            setOppertunitytype(response.data.data.Opportunity_type)
            setOpportunityMode(response.data.data.opportunity_mode)
            setJobdescription(response.data.data.job_description)
            setEmplojob(JSON.parse(response.data.data.employment_type == null) || JSON.parse(response.data.data.employment_type == "") ? "" : JSON.parse(response.data.data.employment_type))
            setCdExclusive(response.data.data.cd_member)
            setClosingdate(response.data.data.closing_date)
            setInstagram(response.data.data.instagram_url)
            setopporvenuedetails(response.data.data.venue)

            setIsLoading(false);
        } catch (error) {
            console.error('Error making POST request:', error);
            setIsLoading(false);
        }
    }
    const uploaddImage = (e) => {
        return 'data:image;base64,' + e.base64Data
    }
    const uploaImage = (e) => {
        return 'data:image;base64,' + e.base64Data
    }

    return (
        <div className='addopportunitycs'>

            <Individual_Header />
            <ToastContainer toastOptions={{ position: "top-right" }} />
            {isLoading ? (
                <div className="spinner-box">
                    <div className="pulse-container">
                        <div className="pulse-bubble pulse-bubble-1"></div>
                        <div className="pulse-bubble pulse-bubble-2"></div>
                        <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='container mb-5 opportunity-height'>
                        <div className='col-12 row'>
                            <label className='label-head'>Title<span className='required-color'> (required)</span></label>
                            <input type='text' className='form-control' value={title} onChange={(e) => setTitle(e.target.value)}>
                            </input>
                        </div>

                        <div className='col-12 row'>
                            <label className='label-head'>Tags</label>
                            <input type='text' className='form-control' value={tags} onChange={(e) => setTags(e.target.value)}>
                            </input>
                        </div>
                        <div className='col-12 row mt-3'>
                            <label className='label-head'>Company logo</label>
                            <input type='file' placeholder='Your answer' className='inputfileupload' onChange={(e) => uploadImage(e, 'logo')} />
                            <h5 className='txtaccfile'>Accepted file types: jpg, jpeg, png, Max. file size: 32 MB.</h5>
                        </div>
                        {logo ? (
                            <>
                                <img src={uploaImage(logo)} alt="Thumbnail" class="mt-3 px-4" style={{ maxWidth: '200px' }} />
                            </>
                        ) : (
                            <p className='ml-4' style={{ fontSize: "20px" }}>No file chosen</p>
                        )}

                        <div className='col-12 row mt-3'>
                            <label className='label-head'>Opportunity image</label>
                            <input type='file' className='inputfileupload' onChange={(e) => uploadopportImage(e, 'oppoimage')} />
                        </div>
                        {oppoimage ? (
                            <>
                                <img src={uploaddImage(oppoimage)} alt="Thumbnail" class="mt-3 px-4" style={{ maxWidth: '200px' }} />
                            </>
                        ) : (
                            <p className='ml-4' style={{ fontSize: "20px" }}>No file chosen</p>
                        )}
                        <div className='col-12 row mt-3 selectwrapperoppor'>
                            <label className='label-head'>Opportunity type</label>
                            <select className='addopportunitydrpform' value={oppertunitytype} onChange={(e) => setOppertunitytype(e.target.value)}>
                                <option>--Select--</option>
                                <option value="Employment">Employment</option>
                                <option value="Courses, Fellowships and Scholarships">Courses, Fellowships and Scholarships</option>
                                <option value="Trainings and Workshops">Trainings and Workshops</option>
                                <option value="Grants and Funds">Grants and Funds</option>
                                <option value="Incubator and Accelerators">Incubator and Accelerators</option>
                                <option value="Contest">Contest</option>
                                <option value="Collaborations">Collaborations</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className='col-12 row mt-3'>
                            {oppertunitytype === 'Employment' && (
                                <>
                                    <h2>Employment type</h2>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="job" value='job' onChange={handleChangeempjob} checked={emplojob.includes('job')} />
                                            <span className="label-text">Job</span>
                                        </label>
                                    </div>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Internship" value='Internship' onChange={handleChangeempjob} checked={emplojob.includes('Internship')} />
                                            <span className="label-text">Internship</span>
                                        </label>
                                    </div>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Volunteering" value='Volunteering' onChange={handleChangeempjob} checked={emplojob.includes('Volunteering')} />
                                            <span className="label-text">Volunteering</span>
                                        </label>
                                    </div>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Freelance Project" value='Freelance Project' onChange={handleChangeempjob} checked={emplojob.includes('Freelance Project')} />
                                            <span className="label-text">Freelance Project</span>
                                        </label>
                                    </div>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Full-time" value='Full-time' onChange={handleChangeempjob} checked={emplojob.includes('Full-time')} />
                                            <span className="label-text">Full-time</span>
                                        </label>
                                    </div>

                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Part-time" value='Part-time' onChange={handleChangeempjob} checked={emplojob.includes('Part-time')} />
                                            <span className="label-text">Part-time</span>
                                        </label>
                                    </div>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Paid" value='Paid' onChange={handleChangeempjob} checked={emplojob.includes('Paid')} />
                                            <span className="label-text">Paid</span>
                                        </label>
                                    </div>
                                    <div >
                                        <label className='checkbox-label'>
                                            <input type="checkbox" name="Unpaid" value='Unpaid' onChange={handleChangeempjob} checked={emplojob.includes('Unpaid')} />
                                            <span className="label-text">Unpaid</span>
                                        </label>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='col-12 row'>
                            <label className='label-head'>Opportunity mode<span className='required-color'> (required)</span></label>
                            <div>
                                <label className='checkbox-label'>
                                    <input
                                        type="radio"
                                        name="OpportunityMode"
                                        value="online"
                                        checked={OpportunityMode === 'online'}
                                        onChange={handleOpportunityModeChange}
                                    />
                                    <span className="label-text">Online</span>
                                </label>
                            </div>
                            <div>
                                <label className='checkbox-label'>
                                    <input
                                        type="radio"
                                        name="eventMode"
                                        value="offline"
                                        checked={OpportunityMode === 'offline'}
                                        onChange={handleOpportunityModeChange}
                                    />
                                    <span className="label-text">Offline</span>
                                </label>
                            </div>
                            <div>
                                <label className='checkbox-label'>
                                    <input
                                        type="radio"
                                        name="eventMode"
                                        value="hybrid"
                                        checked={OpportunityMode === 'hybrid'}
                                        onChange={handleOpportunityModeChange}
                                    />
                                    <span className="label-text">Hybrid</span>

                                </label>
                            </div>
                        </div>
                        {OpportunityMode === 'online' ? (
                            <>
                            </>)
                            : null}
                        {OpportunityMode === 'offline' ? (
                            <>
                                <div className='col-12 row mt-3 communitymrg'>
                                    <label className='label-head '>Location</label>
                                    <input type='text' placeholder='Location' class='form-control  foreventorgani' value={opporvenuedetails} onChange={(e) => setopporvenuedetails(e.target.value)} />
                                </div>
                            </>)
                            : null}
                        {OpportunityMode === 'hybrid' ? (
                            <>
                                <div className='col-12 row mt-3 communitymrg'>
                                    <label className='label-head '>Location</label>
                                    <input type='text' placeholder='Location' class='form-control  foreventorgani' value={opporvenuedetails} onChange={(e) => setopporvenuedetails(e.target.value)} />
                                </div>
                            </>
                        ) : null}
                        <div className='col-12 row'>
                            <div className='col-12 row mb-5'>
                                <label className='label-head'>Description<span className='required-color'> (required)</span></label>
                                <div className='quillrefdoc col-12'>
                                    <div ref={quillRef} />
                                </div>
                            </div>

                            <div className='col-12 row cd-exclusive1 cd-exclusive'>
                                <label className='label-head'>This is a CD exclusive opportunity </label>
                            </div>
                            <div>
                                <label className='checkbox-label'>
                                    <input
                                        type="radio"
                                        name="cdExclusive"
                                        value="Yes"
                                        checked={cdExclusive === 'Yes'}
                                        onChange={handleCD_Exclusive}
                                    />
                                    <span className="label-text">Yes</span>

                                </label>
                            </div>
                            <div>
                                <label className='checkbox-label'>
                                    <input
                                        type="radio"
                                        name="cdExclusive"
                                        value="No"
                                        checked={cdExclusive === 'No'}
                                        onChange={handleCD_Exclusive}
                                    />
                                    <span className="label-text">No</span>

                                </label>
                            </div>

                        </div>
                        <div className='col-12 row'>
                            <label className='label-head'>Application deadline</label>
                            <input type='date' className='form-controlone col-12' value={closingdate} onChange={(e) => setClosingdate(e.target.value)}>
                            </input>
                        </div>

                        <div class='col-12 row mt-3'>
                            <label className='label-head'>External link:</label>
                            <input type='text' placeholder='Enter URL for opportunity link' class='form-control foreventextranal mb-5' value={instagram} onChange={(e) => setInstagram(e.target.value)} />
                        </div>
                        <div className='mt-3 ml-3'>
                            <ReactGoogleReCaptcha
                                onChange={() => handleChange(!captacha)}
                                sitekey="6LeqWS0pAAAAADToj2-tBAkRfKQIUZEWok6S5S4Q"
                                onVerify={handleVerify}
                            />
                        </div>
                        <div className='row col-xl-2 col-md-6'>
                            <button className="btn-nextone mt-4 mb-4" onClick={postData}>Submit</button>
                        </div>
                    </div>
                </>
            )}
            <Footer></Footer>
        </div>
    )
}

export default Addopportunityform;