import CDImage1 from '../Images/CDImage1.jpg';
import photobackone from '../Images/photobackone.jpg';
import manimage from '../Images/manimage.jpg';
import Frameicon from '../Images/Frameicon.jpg';
import Fullbackground from '../Images/Fullbackground.jpg';
import linkedin from '../Images/linkedin.png';
import facebook from '../Images/facebook.jpg';
import twitter from '../Images/twitter.jpg';
import instagram from '../Images/instagram.png';
import youtube from '../Images/youtube.png';
import Allartisanfemale from '../Images/Allartisanfemale.jpg';
import Allartisanmale from '../Images/Allartisanmale.jpg';
import Allartfe2 from '../Images/Allartfe2.jpg';
import CDlogosymbol from '../Images/white1.png';
import NaviationRight from '../Images/NaviationRight.png';
import Signuplearner from '../Images/Signup-learner.jpg';
import imageicon from '../Images/image-icon.png';
import artdir from '../Images/artdir.jpg';
import chrome from '../Images/chrome.png';
import Logooppor from '../Images/Logooppor.jpg';
import Twineimages from '../Images/Twineimages.jpg';
import Designerscallout from '../Images/Designerscallout.jpg';
import Lastlogo from '../Images/Lastlogo.jpg';
import CDlogosymbol1 from '../Images/black1.png'
import cd_background from '../Images/cd_backgroundimage.png'
import Artisan_banner from '../Images/Artisans banner .png'
import Event_banner from '../Images/event banner.png'
import Opportunity_banner from '../Images/opportunity banner.png'
import Artisan_listing from '../Images/Artisan_listing.jpg';
import Become_a_member_listing from '../Images/Become_a_member_listing.jpg';
import Contact_us_listing from '../Images/Contact_us_listing.jpg'
import Donate_to_CD_listing from '../Images/Donate_to_CD_listing.jpg'
import event_listing from '../Images/event_listing.jpg'
import interesting_read_listing from '../Images/interesting_read_listing.jpg'
import opportunity_listing from '../Images/opportunity_listing.jpg'
import Volunteer_with_us_listing from '../Images/Volunteer_with_us_listing.jpg'



const Imagespic = {
        Volunteer_with_us_listing,
        opportunity_listing,
        interesting_read_listing,
        event_listing,
        Donate_to_CD_listing,
        Contact_us_listing,
        Become_a_member_listing,
        Twineimages,
        Lastlogo,
        CDImage1,
        photobackone,
        manimage,
        Frameicon,
        Fullbackground,
        linkedin,
        facebook,
        twitter,
        instagram,
        Allartisanfemale,
        Allartisanmale,
        Allartfe2,
        CDlogosymbol,
        CDlogosymbol1,
        NaviationRight,
        Signuplearner,
        imageicon,
        artdir,
        chrome,
        Logooppor,
        Designerscallout,
        cd_background,
        youtube,
        Artisan_banner,
        Event_banner,
        Opportunity_banner,
        Artisan_listing,
}

export default Imagespic;


