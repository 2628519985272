import { useState, useEffect,useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import './Smaller.css'
import { Button } from 'react-bootstrap';
import { apiURL } from "../Commen/apiurl";
import axios from 'axios';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import Individual_Header from '../Header/new_header';
import { useLocation, useNavigate } from 'react-router';
import ReactGoogleReCaptcha from "react-google-recaptcha";
function Smaller() {
    const location = useLocation();
    const recaptcha = useRef();
    // const { ID, Flag } = location.state;
    let id = 0;
    let Flag = 'I'
    try {
      const { ID, Flag } = location.state;
      id = ID;
      Flag = Flag
  
    } catch (ex) { }
    const navigate = useNavigate()
    const [tittle, settittle] = useState("")
    const [website_name, setwebsite_name] = useState("")
    const [website_url, setwebsite_url] = useState("")
    const [description, setdescription] = useState("")
    const [response, setResponse] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setAPIResponse] = useState("");
    const [token, setToken] = useState("");
    const [captacha, setcaptacha] = useState(false);
    const handleVerify = (token) => {
        console.log('CAPTCHA verified. Token:', token);
        setToken(token);
        
    };
    const handleChange =(value)=> {
        setcaptacha(value)
    }
    useEffect(() => {
        console.log(id,"id")
        if (id != 0) {
            getData()
            window.scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
    }, []);

    const postData = async () => {
        if (tittle) {
            if (captacha == true) {
                setIsLoading(true);
                var payloadvolu = {
                    "id": id,
                    "Tittle": tittle,
                    "website_name": website_name,
                    "website_url": website_url,
                    "Description": description,
                    "Flag": Flag,
                }
                try {
                    const response = await axios.post(`${apiURL}/UserMaster/smaller_form`, payloadvolu, {});
                    setIsLoading(false);
                    // showAlert(response.data.message);
                    setResponse(response.data);
                    if (Flag == "U") {
                        showAlert1(response.data.message);
                        navigate('/Admin_approval')
                    } else {
                        showAlert(response.data.message);
                        setTimeout(function () {
                            window.location.href = 'https://www.creativedignity.org/';
                        }, 4000);
                    }
                } catch (error) {
                    console.error('Error making POST request:', error);
                    setIsLoading(false);
                    showAlerterr();
                }
            } else {
                toast.error('Please verify the CAPTCHA');
            }
        } else {
            toast.error('Please Enter Event Title');
        }
    };
    const showAlerterr = () => {
        Swal.fire({
            title: 'Smaller Added Failed',
            confirmButtonText: 'OK',
            confirmButtonColor: '#D19426',
            customClass: {
                confirmButton: 'addsubmitevent',
            },
        });
    };
    const showAlert1 = () => {
        Swal.fire({
            title: 'Smaller Form Updated Successfully',
            confirmButtonText: 'OK',
            confirmButtonColor: '#D19426',
            customClass: {
                confirmButton: 'addsubmitevent',
            },
        });
    };
    const showAlert = () => {
        Swal.fire({
            title: 'Thank you for sharing an insightful read with us, it will be listed on the page soon!',
            confirmButtonText: 'OK',
            confirmButtonColor: '#D19426',
            customClass: {
                confirmButton: 'addsubmitevent',
            },
        });
    };
    const getData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${apiURL}/Get_all_module/get_by_id?id=${id}&Flag=Smaller`);
            setAPIResponse(response.data.data)
            settittle(response.data.data.Tittle)
            setwebsite_name(response.data.data.website_name)
            setwebsite_url(response.data.data.website_url)
            setdescription(response.data.data.Description)
            setIsLoading(false);
        } catch (error) {
            console.error('Error making POST request:', error);
            setIsLoading(false);
        }
    }
    return (
        <div className='smallerformcss'>

            <Individual_Header />
            <ToastContainer toastOptions={{ position: "top-right" }} />
            {isLoading ? (
                <div className="spinner-box">
                    <div className="pulse-container">
                        <div className="pulse-bubble pulse-bubble-1"></div>
                        <div className="pulse-bubble pulse-bubble-2"></div>
                        <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='container smaller_form_height'>
                        <div className='col-12 row mt-3'>
                            <label className='label-head'>Title<span className='required-color'> (required)</span></label>
                            <input type='text' className='form-control' value={tittle} onChange={(e) => settittle(e.target.value)} />
                        </div>
                        <div className='col-12 row mt-3'>
                            <label className='label-head'>Website name</label>
                            <input type='text' className='form-control' value={website_name} onChange={(e) => setwebsite_name(e.target.value)} />
                        </div>
                        <div className='col-12 row mt-3'>
                            <label className='label-head'>Website URL</label>
                            <input type='text' className='form-control' value={website_url} onChange={(e) => setwebsite_url(e.target.value)} />
                        </div>
                        <div className='col-12 row mt-3'>
                            <label className='label-head'>Description</label>
                            <textarea type='text' className='text-area mb-3 textareaone' row='10' col='10' value={description} onChange={(e) => setdescription(e.target.value)} ></textarea>
                        </div>
                        <div className='mt-3 ml-2'>
                            <ReactGoogleReCaptcha
                                onChange={()=>handleChange(!captacha)}
                                sitekey="6LeqWS0pAAAAADToj2-tBAkRfKQIUZEWok6S5S4Q"
                                onVerify={handleVerify}
                            />
                        </div>
                        <div className='col-4 row'>
                            <Button className='btn-next mt-4 mb-4' onClick={postData}>Submit</Button>
                        </div>
                    </div>
                </>
            )}
            <div><Footer /></div>
        </div>
    )
}

export default Smaller