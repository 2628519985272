import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from '../Header/header';
import Images from '../Images/Imagespic';
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { apiURL } from "../Commen/apiurl"
import axios from 'axios';
import '../Opportunitypg.js/Opportunitiessummary.css';
import { Button } from 'react-bootstrap';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Footer from '../Footer/footer'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import Individual_Header from '../Header/new_header';
import SocialShareModal from '../New/Social_share';
import ReactLoading from "react-loading";
import { BiWorld } from 'react-icons/bi';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

function EventIndividual() {

    const { eventid } = useParams();

    let event_id = window.atob(eventid);
    const [eventindlist, seteventindlist] = useState('')
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [image, setimage] = useState()
    const [banerimage, setbanerimage] = useState()
    const [eventData, setEventData] = useState(null);
    const [Opportunitity, setOpportunitity] = useState('')
    const [sharedData, setSharedData] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [platform_link, setPlatform_link] = useState('');
    const descriptionHtml = eventindlist.description;

    useEffect(() => {
        fetchEventIndividual(event_id);
        window.scrollTo(0, 0);
    }, [event_id]);

    const openShareModal = () => {
        setIsShareModalOpen(true);
    };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };
    const fetchEventIndividual = (id) => {
        setIsLoading(true);
        axios.get(`${apiURL}/UserMaster/event_view_get_id?id=${id}`)
            .then(response => {
                setEventData(response.data.data[0]);
                setOpportunitity(response.data.data[0]);
                seteventindlist(response.data.data[0]);
                setPlatform_link(response.data.data[0].link);
                setimage(JSON.parse(response.data.data[0]['event_image']))
                setSharedData({
                    eventTitle: response.data.data[0].event_title,
                    eventImage: response.data.data[0]['event_image'],
                    eventStartDate: formattedStartDate,
                    eventVenue: response.data.data[0].venue_details,
                    city: response.data.data[0].city,
                    state: response.data.data[0].state,
                });
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching artisan data:", error);
                setIsLoading(false);
            });
    };
    const eventStartTime = eventindlist ? eventindlist.event_start_time : '';
    const eventEndTime = eventindlist ? eventindlist.event_end_time : '';
    const event_start_date = eventindlist ? eventindlist.event_start_date : '';
    const formatDateToMonthDateYearWithDay = (dateString) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    const formattedStartDate = formatDateToMonthDateYearWithDay(event_start_date);
    function formatTimeWithAMPM(time) {
        const [hours, minutes] = time.split(':').map(Number);
        let period = 'AM';

        if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                hours -= 12;
            }
        }

        return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }
    const convertToAMPM = (time) => {
        const [hours, minutes] = time.split(':');
        let period = 'AM';
        let hours12 = parseInt(hours, 10);
        if (hours12 >= 12) {
            period = 'PM';
            return `${hours12}:${minutes} ${period}`;
        } else if (hours12 === 0) {
            return `${hours12}:${minutes} ${period}`;
        }
        return `${hours12}:${minutes} ${period}`;
    };

    const locationContent = (eventindlist) => {
        let locationString = '';

        if (eventindlist.venue_details) {
            locationString += eventindlist.venue_details;
        }

        if (eventindlist.city) {
            locationString += (locationString ? ', ' : '') + eventindlist.city;
        }

        if (eventindlist.state) {
            locationString += (locationString ? ', ' : '') + eventindlist.state;
        }

        return locationString;
    };
    return (
        <div className='opportunitypagecss'>
            <Individual_Header />
            <div className='container' style={{ marginTop: '115px' }}>
                {isLoading ? (
                    <div className='d-flex justify-content-center mt-5'>
                        <ReactLoading type="spin" color="#134f5c" height={100} width={50} />
                    </div>
                ) : (
                    <>
                        <div className='d-flex main-content-section'>
                            <div className='col-xl-2 col-md-2 col-lg-2 left-section'></div>
                            <div className='col-xl-8 col-md-8 col-lg-8 right-section'>
                                <div>
                                    <div class="cd_breadcrumbs_container px-2 mb-5 br-mrg-top">
                                        <span>
                                            <span><a href="https://www.creativedignity.org/">Home</a>
                                            </span> &gt;  <span><a href="/event">Events</a>
                                            </span> &gt; <span>{eventindlist.event_title}</span>
                                        </span>
                                    </div>
                                </div>
                                {image ?
                                    <img src={`data:image/png;base64,${image.base64Data}`} alt="Base64 Image" className='imglog opper-individual-img mt-5 mb-5' /> :
                                    <img src={Images.Logooppor} className='imglog mt-5 mb-5'>

                                    </img>
                                }
                                <h3 className='event-tarasha-text pl-0 ml-0'>
                                    {eventindlist.cd_member === "Yes" ? (
                                        <button
                                            variant="secondary"
                                            className='event-buttons ml-0 '
                                        >
                                            <span className='spanlist ml-0'>CD Exclusive</span>
                                        </button>
                                    ) : null}

                                </h3>
                                <h1 className='event-junior-tarasha-text hongkong-font ml-0'>{eventindlist.event_title}</h1>
                                <div className='row'>
                                    <div className='col-xl-12 col-md-12 col-lg-12 mt-2 tags_fit mb-3 pl-0' style={{
                                        alignItems: 'center',
                                        gap: '15px',
                                        paddingLeft: '0px',
                                        whiteSpace: "normal"
                                    }} >
                                        {eventindlist.categories ? JSON.parse(eventindlist.categories).map((category, index) => (
                                            <Button
                                                key={index}
                                                className='event-buttons event_tags ml-2  '
                                            >
                                                <span className='spanlist'>{category}</span>
                                            </Button>
                                        )) : null}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className='mt-1'>
                                        <svg className='cal-icon' xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 28 30" fill="none ">
                                            <path d="M24.5455 2.72727H23.1818V0H20.4545V2.72727H6.81818V0H4.09091V2.72727H2.72727C1.22727 2.72727 0 3.95455 0 5.45455V27.2727C0 28.7727 1.22727 30 2.72727 30H24.5455C26.0455 30 27.2727 28.7727 27.2727 27.2727V5.45455C27.2727 3.95455 26.0455 2.72727 24.5455 2.72727ZM24.5455 27.2727H2.72727V9.54545H24.5455V27.2727Z" fill="#828282" />
                                        </svg>
                                    </div>

                                    <div className='pl-4'>
                                        {eventindlist && eventindlist && (
                                            <p className='' style={{ fontSize: '15px' }}>
                                                {event_start_date === eventindlist.event_end_date
                                                    ? `${formatDateToMonthDateYearWithDay(event_start_date)} - ${convertToAMPM(eventStartTime)} to ${convertToAMPM(eventindlist.event_end_time)}`
                                                    : `${formatDateToMonthDateYearWithDay(event_start_date)} - ${convertToAMPM(eventStartTime)} to ${formatDateToMonthDateYearWithDay(eventindlist.event_end_date)} - ${convertToAMPM(eventindlist.event_end_time)}`
                                                }
                                            </p>
                                        )}
                                    </div>

                                </div>
                                <div style={{ display: 'flex' }}>
                                    {eventindlist.event_mode === "online" && (
                                        <>
                                            <div className='mt-1'>
                                                <FaMapMarkerAlt className='heightworld' />
                                            </div>
                                            <div className='pl-4'>
                                                <p style={{ fontSize: '15px' }}><a
                                                    href={`http://${platform_link}`}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className=''
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <span className='location-added'>Online,  {eventindlist.platform_name}  -  {platform_link}</span>
                                                </a></p>
                                            </div>
                                        </>
                                    )}
                                    {(eventindlist.event_mode === "offline" || eventindlist.event_mode === "hybrid") &&
                                        <>
                                            <div className='mt-1'>
                                                <FaMapMarkerAlt className='heightworld' />
                                            </div>
                                            <div className='pl-4'>
                                                <p className='' style={{ fontSize: '15px' }}>
                                                    {eventindlist.venue_details && (
                                                        <span className='location-added'>{eventindlist.venue_details}</span>
                                                    )}

                                                    {eventindlist.city && (
                                                        <>
                                                            {eventindlist.venue_details && <span className='location-added'>, </span>}
                                                            <span className='location-added'>{eventindlist.city}</span>
                                                        </>
                                                    )}

                                                    {eventindlist.state && (
                                                        <>
                                                            {(eventindlist.venue_details || eventindlist.city) && <span className='location-added'>, </span>}
                                                            <span className='location-added'>{eventindlist.state}</span>
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div style={{ display: 'flex' }}>
                                    {eventindlist.event_web_site && (
                                        <>
                                            <div className='mt-1'>
                                                <BiWorld className='heightworld-url ' />
                                            </div>
                                            <div className='pl-4'>
                                                <p style={{ fontSize: '15px' }}>
                                                    <a className='' href={eventindlist.event_web_site} style={{ textDecoration: 'none', overflowWrap: 'anywhere' }} target="_blank" rel="noopener noreferrer">
                                                        <span className='location-added'>{eventindlist.event_web_site}</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {eventindlist.organizer && (
                                    <div className='row px-3 mb-2 location-added'>
                                        Event Organizer: {eventindlist.organizer}
                                    </div>
                                )}
                                {eventindlist.event_cost && (
                                    <div className='row px-3 mb-2 location-added'>
                                        Event Fee:  {eventindlist.event_cost}
                                    </div>
                                )}
                                <div className='row'>
                                    <div className='col-12 col-md-2'>
                                        <Button className='btnshare btn-lg'
                                            onClick={openShareModal}>Share</Button>
                                    </div>
                                    <div className='mt-5'>
                                        <div className='individual-para' dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                                    </div>
                                    <div className='col-12 mt-5 mb-5'>
                                        {banerimage ?
                                            <img src={`data:banerimage/png;base64,${banerimage.base64Data}`} alt="Base64 Image" />
                                            : null
                                        }

                                    </div>
                                </div>
                                {eventindlist &&
                                    <div>
                                        <AddToCalendarButton
                                            name={eventindlist.event_title}
                                            // location={() => locationContent(eventindlist)}
                                            // location={eventindlist.state? eventindlist.state : platform_link}
                                            location={eventindlist.event_mode === "online" ? platform_link : locationContent(eventindlist)}
                                            options={['Apple', 'Google', 'Outlook.com']}
                                            startDate={eventindlist.event_start_date}
                                            endDate={eventindlist.event_end_date}
                                            startTime={eventindlist.event_start_time}
                                            endTime={eventindlist.event_end_time}
                                            timeZone="Asia/Kolkata"
                                            // description={descriptionHtml}
                                            description={`${`<a href="https://app.creativedignity.org/EventIndividual/${eventid}">https://app.creativedignity.org/EventIndividual/${eventid}</a>`}\n\n ${descriptionHtml}`}
                                        ></AddToCalendarButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                )}

            </div>
            <Footer />
            <>
                <SocialShareModal
                    isOpen={isShareModalOpen}
                    onClose={closeShareModal}
                    shareUrl={`https://app.creativedignity.org/EventIndividual/${eventid}`}
                    title={eventindlist.event_title}
                    location={eventindlist.venue}
                    closingDate={eventindlist.event_start_date}
                    eventId={eventid}
                />

            </>
        </div>
    )
}

export default EventIndividual