import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Footer from '../Footer/footer'
import Formone from "./Formone";
import Formtwo from "./Formtwo";
import Formthree from "./Formthree";
import Memberformone from "../MemerForm/Memberformone";
import axios from 'axios';
import { apiURL } from "../Commen/apiurl"
import Swal from 'sweetalert2';
import Individual_Header from "../Header/new_header";

function ArtisanForm() {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  let ID = 0;
  let update = 'I'
  try {
    const { id, flag } = location.state;
    ID = id;
    update = flag

  } catch (ex) { }
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const [artisaneditid, setartisaneditid] = useState('')
  const [artisanformData, setArtisanFormData] = useState({

    orginizationval: "",
    areyouval: "",
    yourname: "",
    brandname: "",
    bioorginization: "",
    aboutthecraft: "",
    acceptpracticed: "",
    acceptpracticedone: "",
    materialsuse: "",
    materialsuseone: "",
    productrange: "",
    craftother: "",
    materialother: "",
    townvilcity: "",
    stateval: "",
    addressdetails: "",
    contactnumber: "",
    emailid: "",
    socialsite: "",
    siteurl: "",
    socialsiteone: "",
    siteurlone: "",
    socialsitetwo: "",
    siteurltwo: "",
    socialsitethree: "",
    siteurlthree: "",
    socialsitefour: "",
    siteurlfour: "",
    socialsitefive: "",
    siteurlfive: "",
    websitename: "",
    Websiteurl: "",
    storename: "",
    storenameone: "",
    storenametwo: "",
    storeurl: "",
    storeurlone: "",
    storeurltwo: "",
    languagescomunit: "",
    Artisan_card_no: "",

  });

  const [yourlogos, setYourLogos] = useState();
  const [b2B_formthree, setB2B_formthree] = useState([])

  const [b2c_formthree, setB2c_Formthree] = useState(false)
  const [ecommerce, setEcommerce] = useState(false)
  const [export_fthree, setExport_Fthree] = useState(false)
  const [retail, setRetail] = useState(false)
  const [Socialmedia, setSocialMedia] = useState(false)
  const [localmarket, setLocalMarket] = useState(false)
  const [other_fthree, setOther_Fthree] = useState(false)
  const [national, setNational] = useState([])

  const [stateaward, setstateaward] = useState(false)
  const [otheraward_fthree, setOtherAward_Fthree] = useState(false)
  const [notapplie, setnotapplie] = useState(false)
  const [zerotofive, setZerotoFive] = useState([])

  const [estore, setEstore] = useState([]);
  const [fivetoten, setFivetoTen] = useState(false)
  const [tentofifteen, settentoFifteen] = useState(false)
  const [artisanres, setArtisanRes] = useState("");
  const [artisanlogo, setArtisanLogo] = useState("")


  const [artisanlogotwo, setArtisanLogoTwo] = useState("")
  const [artisanlogodrop, setArtisanLogoDrop] = useState([])
  const [anyrecognition, setAnyRecognition] = useState("")

  const [areumaster, setAreuMaster] = useState("")

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedmaterials, setselectedmaterials] = useState("");
  const [firstcheck, setfirstcheck] = useState(false);
  const [secondcheck, setsecondcheck] = useState(false);
  const [threecheck, setthreecheck] = useState(false);
  const [agree, setagree] = useState(false)
  const [languageval, setLanguageval] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [craftCategoriesname, setCraftCategoriesname] = useState([]);
  const [inputKey, setInputKey] = useState(0);
  const inputRef = useRef()
  const [isLoading, setIsLoading] = useState(false);
  const [event_list, setevent_list] = useState([])
  const [acceptPracticedOneOptions, setAcceptPracticedOneOptions] = useState([]);
  const [materialsuseOneOptions, setMaterialsuseOneOptions] = useState([]);
  const [craftCategoryId, setCraftCategoryId] = useState('');
  const [craftNames, setCraftNames] = useState([]);
  const [craftCategories, setCraftCategories] = useState([]);
  const [subcraft, setsubcraft] = useState('')
  const [materialCategories, setMaterialCategories] = useState([]);
  const [materialNames, setMaterialNames] = useState([]);
  const [submaterial, setsubmaterial] = useState('')
  const [craftOther, setCraftOther] = useState('');
  const [materialOther, setMaterialOther] = useState('');
  const [token, setToken] = useState("");
  const [captacha, setcaptacha] = useState(false);
  console.log(captacha, 'ffff')
  const handleVerify = (token) => {
    console.log('CAPTCHA verified. Token:', token);
    setToken(token);

  };
  const handleCaptachaChange = (value) => {
    setcaptacha(value)
  }
  const handleChange = (event) => {
    setArtisanFormData({
      ...artisanformData,
      [event.target.name]: event.target.value,

    });
    if (event.target.name === 'craftother') {
      setCraftOther(event.target.value);
    }
    if (event.target.name === 'materialother') {
      setMaterialOther(event.target.value);
    }
  };
  useEffect(() => {
    if (ID != 0) {
      fetchartisandetails(ID);
      getstateList1();
      getmaterialList();


    }
    getstateList1();
    getmaterialList();
  }, []);
  const fetchartisandetails = (id) => {
    setIsLoading(true);
    axios.get(`${apiURL}/Get_all_module/get_by_id?id=${id}&Flag=Artisan`, {
      headers: {
        "accept": "application/json",
      }
    }).then(resp => {
      setevent_list(resp.data.data)
      setartisaneditid(resp.data.data.id)
      setArtisanFormData({
        areyouval: resp.data.data.are_you, orginizationval: resp.data.data.organization_name, brandname: resp.data.data.brand_name,
        bioorginization: resp.data.data.bio_about_the_organization, aboutthecraft: resp.data.data.about_the_craft,
        productrange: resp.data.data.product_range, townvilcity: resp.data.data.your_city, stateval: resp.data.data.state, addressdetails: resp.data.data.address,
        contactnumber: resp.data.data.contact_no, emailid: resp.data.data.Email_id, socialsite: resp.data.data.Add_Social_Site_1, siteurl: resp.data.data.site_url_1, socialsiteone: resp.data.data.Add_Social_Site_2, siteurlone: resp.data.data.site_url_2, socialsitetwo: resp.data.data.Add_Social_Site_3,
        siteurltwo: resp.data.data.site_url_3, socialsitethree: resp.data.data.Add_Social_Site_4, siteurlthree: resp.data.data.site_url_4, socialsitefour: resp.data.data.Add_Social_Site_5, siteurlfour: resp.data.data.site_url_5,
        craftother: resp.data.data.craft_others,
        materialother: resp.data.data.meterial_others,
        websitename: resp.data.data.website_name, Websiteurl: resp.data.data.website_url, storename: resp.data.data.store[0]['store_name'], storeurl: resp.data.data.store[0]['store_url'], storenameone: resp.data.data.store_name2, storenametwo: resp.data.data.store_name3, storeurlone: resp.data.data.store_url2, storeurltwo: resp.data.data.store_url3,
        Artisan_card_no: resp.data.data.Artisan_card_no
      })

      // setB2B_formthree(resp.data.data.won_any_recognition)
      setSelectedCategory(resp.data.data.craft_practiced_under_the_category_1)
      getstateList(resp.data.data.craft_practiced_under_the_category_1)
      setsubcraft(resp.data.data.craft_practiced_under_the_category_2)
      setCraftOther(resp.data.data.craft_others)
      setMaterialOther(resp.data.data.meterial_others)
      setselectedmaterials(resp.data.data.materials_used_for_the_craft_1)
      getmaterialList1(resp.data.data.materials_used_for_the_craft_1)
      setsubmaterial(resp.data.data.materials_used_for_the_craft_2)
      setMaterialOther(resp.data.data.meterial_others)
      setArtisanLogo(JSON.parse(resp.data.data.logo))
      setAnyRecognition(anyrecognition)
      setAreuMaster(areumaster)





      setUploadedImages(JSON.parse(resp.data.data.files))
      setArtisanLogoTwo(JSON.parse(resp.data.data.image))
      setEstore(resp.data.data.Add_an_estore_you)
      let checked = resp.data.data.Add_an_estore_you ? resp.data.data.Add_an_estore_you : []
      checked.forEach((value) => {
        if (value === 'Add an e-store you sell at1') {
          setfirstcheck(true);
        } else if (value === 'Add an e-store you sell at2') {
          setsecondcheck(true);
        } else if (value === 'Add an e-store you sell at3') {
          setthreecheck(true);
        }
      });

      setAnyRecognition(resp.data.data.involved_in_Craft)
      setAreuMaster(resp.data.data.Are_you_a)
      setNational(resp.data.data.your_products)
      setZerotoFive(resp.data.data.practicing_the_craft)
      setB2B_formthree(resp.data.data.won_any_recognition)
      setLanguageval(JSON.parse(resp.data.data.communicate_languages))
      setIsLoading(false);
    })
      .catch(err => {
        setIsLoading(false);
        console.log(err)
      })
  }

  // const uploadlogo = (e, flag) => {
  //   let file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = handleReaderLoaded.bind(this, flag);
  //     reader.readAsBinaryString(file);


  //   }
  // };

  // const handleReaderLoaded = (flag, e) => {
  //   let binaryString = e.target.result;
  //   if (flag == 'logo') {
  //     setArtisanLogo({
  //       base64Data: btoa(binaryString),
  //     });
  //   }
  // };

  const uploadlogo = (e, flag) => {
    let file = e.target.files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = handleReaderLoaded.bind(this, flag);
      reader.readAsBinaryString(file);
    } else {
      // Handle the case where a non-image file is selected (e.g., show an error message)
      toast.error('Please choose a valid image file.');
    }
  };

  const handleReaderLoaded = (flag, e) => {
    let binaryString = e.target.result;
    if (flag === 'logo') {
      setArtisanLogo({
        base64Data: btoa(binaryString),
      });
    }
  };

  const uploadlogotwo = (e, flag) => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      // Capture the filename before reading the file
      const fileName = file.name;

      reader.onload = handleReaderLoadedtwo.bind(this, flag, fileName);
      reader.readAsBinaryString(file);
    }
  };

  const handleReaderLoadedtwo = (flag, fileName, e) => {
    let binaryString = e.target.result;
    if (flag === 'logotwo') {
      setArtisanLogoTwo({
        base64Data: btoa(binaryString),
        fileName: fileName, // Use the captured filename
      });
    }
  };

  const uploadlogodrop = (e) => {
    const files = e.target.files;
    const previews = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const uniqueId = new Date().getTime();

      const reader = new FileReader();
      reader.onload = (event) => {
        previews.push({ id: uniqueId, file, base64: event.target.result });
        if (previews.length === files.length) {
          setUploadedImages((existingPreviews) => [...existingPreviews, ...previews]);
          setInputKey((prevKey) => prevKey + 1); // Increment the inputKey
          inputRef.current.value = ''; // Clear the input value
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDescriptionChange = (index, newDescription) => {
    const updatedImages = [...uploadedImages];
    updatedImages[index] = {
      ...updatedImages[index],
      description: newDescription,
    };
    setUploadedImages(updatedImages);
  };

  const handleCheckboxStore = (event) => {
    const valueToAddOrRemove = event.target.value;
    const name = event.target.name;

    if (name === 'Add an e-store you sell at1') {
      if (event.target.checked) {
        setfirstcheck(true);
      } else {
        setfirstcheck(false);
      }
    }
    if (name === 'Add an e-store you sell at2') {
      if (event.target.checked) {
        setsecondcheck(true);
      } else {
        setsecondcheck(false);
      }
    }
    if (name === 'Add an e-store you sell at3') {
      if (event.target.checked) {
        setthreecheck(true);
      } else {
        setthreecheck(false);
      }
    }

    if (event.target.checked) {
      setEstore([...estore, valueToAddOrRemove]);
    } else {
      setEstore(estore.filter((item) => item !== valueToAddOrRemove));
    }
  };
  const handleChangecivildemo = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (national.includes(valueToAddOrRemove)) {
      const newArray = national.filter(day => day !== valueToAddOrRemove);
      setNational(newArray);
    } else {
      const newArray = [...national, valueToAddOrRemove];
      setNational(newArray);
    }
  };
  const handleChangerecognition = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (b2B_formthree.includes(valueToAddOrRemove)) {
      const newArray = b2B_formthree.filter(day => day !== valueToAddOrRemove);
      setB2B_formthree(newArray);
    } else {
      const newArray = [...b2B_formthree, valueToAddOrRemove];
      setB2B_formthree(newArray);
    }
  };
  const handleChangeyears = (event) => {
    const valueToAddOrRemove = event.target.value;
    if (zerotofive.includes(valueToAddOrRemove)) {
      const newArray = zerotofive.filter(day => day !== valueToAddOrRemove);
      setZerotoFive(newArray);
    } else {
      const newArray = [...zerotofive, valueToAddOrRemove];
      setZerotoFive(newArray);
    }
  };
  const getstateList = (selectedCraftCategoryId) => {
    if (selectedCraftCategoryId !== null) {
      axios.get(`https://app.creativedignity.org:8001/UserMaster/get_craft_name_id?name=${selectedCraftCategoryId}`)
        .then(resp => {
          // Sort craft names alphabetically
          const sortedCraftNames = resp.data.data.sort((a, b) => {
            if (a.name === 'Others') {
              return 1; // "Others" comes last
            } else if (b.name === 'Others') {
              return -1; // "Others" comes last
            } else {
              return a.name.localeCompare(b.name);
            }
          });


          setCraftNames(sortedCraftNames);
        })
        .catch(error => {
          console.error('Error fetching craft names:', error);
        });
    }
  }
  const getstateList1 = () => {
    axios
      .get('https://app.creativedignity.org:8001/UserMaster/get_craft_category')
      .then((resp) => {
        // Sort craft categories alphabetically by name
        const sortedCraftCategories = resp.data.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        // Assuming setCraftCategories is used to set the entire array
        setCraftCategories(sortedCraftCategories);

        // If you also want to store just the names in a separate state variable
        const categoryNames = sortedCraftCategories.map((category) => category.name);
        setCraftCategoriesname(categoryNames);
      })
      .catch((error) => {
        console.error('Error fetching craft categories:', error);
      });
  };
  const handleCategoryChange = (e) => {
    const selectedCraftCategoryId = e.target.value;
    setSelectedCategory(selectedCraftCategoryId);
    getstateList(selectedCraftCategoryId);
  };
  const handlesubcaraft = (e) => {
    const subCraftId = e.target.value;
    setsubcraft(subCraftId);
  }
  const handleMaterialsChange = (e) => {
    const selectedMaterialCategoryId = e.target.value;
    setselectedmaterials(selectedMaterialCategoryId);
    getmaterialList1(selectedMaterialCategoryId)
  };
  const handlesubmaterial = (e) => {
    const subMaterialId = e.target.value;
    setsubmaterial(subMaterialId);
  }

  //material APi  
  const getmaterialList = () => {
    axios
      .get('https://app.creativedignity.org:8001/UserMaster/get_meterial_category')
      .then(response => {
        // Sort material categories alphabetically
        const sortedMaterialCategories = response.data.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setMaterialCategories(sortedMaterialCategories);
      })
      .catch(error => {
        console.error('Error fetching material categories:', error);
      });
  };

  const getmaterialList1 = (selectedMaterialCategoryId) => {
    if (selectedMaterialCategoryId !== null) {
      axios
        .get(`https://app.creativedignity.org:8001/UserMaster/get_meterial_name_id?name=${selectedMaterialCategoryId}`)
        .then(response => {
          // Sort material names alphabetically
          const sortedMaterialNames = response.data.data.sort((a, b) => {
            if (a.name === 'Others') {
              return 1; // "Others" comes last
            } else if (b.name === 'Others') {
              return -1; // "Others" comes last
            } else {
              return a.name.localeCompare(b.name);
            }
          });
          setMaterialNames(sortedMaterialNames);
        })
        .catch(error => {
          console.error('Error fetching material names:', error);
        });
    }
  };
  const submitArtisanForm = async () => {
    if (agree || (ID != 0)) {
      if (captacha == true) {
        setIsLoading(true)
        var payloadform = {
          // 1stform

          "are_you": artisanformData.areyouval,
          "organization_name": artisanformData.orginizationval,
          'brand_name': artisanformData.brandname,
          "bio_about_the_organization": artisanformData.bioorginization,
          "about_the_craft": artisanformData.aboutthecraft,
          "craft_practiced_under_the_category_1": selectedCategory,
          "craft_practiced_under_the_category_2": subcraft,
          "materials_used_for_the_craft_1": selectedmaterials,
          "materials_used_for_the_craft_2": submaterial,

          "product_range": artisanformData.productrange,
          "logo": JSON.stringify(artisanlogo),
          "files": JSON.stringify(uploadedImages),
          "image": JSON.stringify(artisanlogotwo),
          "craft_others": artisanformData.craftother,
          "meterial_others": artisanformData.materialother,

          // 2nd form
          "your_city": artisanformData.townvilcity,
          "state": artisanformData.stateval,
          "address": artisanformData.addressdetails,
          "contact_no": artisanformData.contactnumber,
          "craft_others": artisanformData.craftother,
          "meterial_others": artisanformData.materialother,
          "Email_id": artisanformData.emailid,
          "Add_Social_Site_1": artisanformData.socialsite,
          "site_url_1": artisanformData.siteurl,
          "Add_Social_Site_2": artisanformData.socialsiteone,
          "site_url_2": artisanformData.siteurlone,
          "Add_Social_Site_3": artisanformData.socialsitetwo,
          "site_url_3": artisanformData.siteurltwo,
          "Add_Social_Site_4": artisanformData.socialsitethree,
          "site_url_4": artisanformData.siteurlthree,
          "Add_Social_Site_5": artisanformData.socialsitefour,
          "site_url_5": artisanformData.siteurlfour,
          // 3rd form  
          "website_name": artisanformData.websitename,
          "website_url": artisanformData.Websiteurl,
          "Add_an_estore_you": estore,
          "your_products": national,
          "won_any_recognition": b2B_formthree,
          "practicing_the_craft": zerotofive,
          "communicate_languages": JSON.stringify(languageval),
          "involved_in_Craft": anyrecognition,
          "Are_you_a": areumaster,
          "Artisan_card_no": artisanformData.Artisan_card_no,

          "Flag": update,
          "id": ID,
          "store": [
            {
              "store_name": artisanformData.storename,
              "store_url": artisanformData.storeurl,
            }
          ],
          "store_name2": artisanformData.storenameone,
          "store_name3": artisanformData.storenametwo,
          "store_url2": artisanformData.storeurlone,
          "store_url3": artisanformData.storeurltwo
        }
        try {
          const response = await axios.post(`${apiURL}/UserMaster/UserMaster/artician_form_data`, payloadform, {
          });
          setIsLoading(false);
          setArtisanRes(response.data);

          if (update == "U") {
            showAlert1();
            navigate('/Admin_approval')
          }
          else {
            showAlert();
            navigate('/artisan')
          }
        } catch (error) {
          console.log('Server response:', error.response);
          showAlerterr();
          setIsLoading(false);
        }
      } else {
        toast.error('Please verify the CAPTCHA');
      }
    } else {
      toast.error('Please Check Terms & Conditions')
    }
  };

  const showAlerterr = () => {
    Swal.fire({
      title: 'Profile Added Failed',
      confirmButtonText: 'OK',
      confirmButtonColor: '#D19426',
      customClass: {
        confirmButton: 'addsubmitevent',
      },
    });
  };
  const showAlert = () => {
    Swal.fire({
      title: 'Thank you for sharing your work with us, you will be added to our artisan directory soon!',
      confirmButtonText: 'OK',
      confirmButtonColor: '#D19426',
      customClass: {
        confirmButton: 'addsubmitevent',
      },
    });
  };
  const showAlert1 = () => {
    Swal.fire({
      title: 'Artisan Form Updated Successfully',
      confirmButtonText: 'OK',
      confirmButtonColor: '#D19426',
      customClass: {
        confirmButton: 'addsubmitevent',
      },
    });
  };

  return (
    <div>
      <Individual_Header />
      <ToastContainer toastOptions={{ position: "top-right" }} />
      {isLoading ? (
        <div className="spinner-box">
          <div className="pulse-container">
            <div className="pulse-bubble pulse-bubble-1"></div>
            <div className="pulse-bubble pulse-bubble-2"></div>
            <div className="pulse-bubble pulse-bubble-3"></div>
          </div>
        </div>
      ) : (
        <>
          <div className='container' style={{ marginTop: '150px' }}>
            {activeStep === 1 ? <Formone

              arisandata={artisanformData}
              handleChange={handleChange}
              next={handleNext}
              artisanlogo={artisanlogo}
              artisaneditid={artisaneditid}
              yourlogos={yourlogos}
              artisanlogotwo={artisanlogotwo}
              uploadlogo={uploadlogo}
              uploadlogotwo={uploadlogotwo}
              uploadlogodrop={uploadlogodrop}
              uploadedImages={uploadedImages}
              setUploadedImages={setUploadedImages}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedmaterials={selectedmaterials}
              setselectedmaterials={setselectedmaterials}
              handleDescriptionChange={handleDescriptionChange}
              inputKey={inputKey}
              inputRef={inputRef}
              handleCategoryChange={handleCategoryChange}
              handleMaterialsChange={handleMaterialsChange}
              acceptPracticedOneOptions={acceptPracticedOneOptions}
              setAcceptPracticedOneOptions={setAcceptPracticedOneOptions}
              materialsuseOneOptions={materialsuseOneOptions}
              getstateList={getstateList}
              craftCategories={craftCategories}
              materialCategories={materialCategories}
              craftNames={craftNames}
              materialNames={materialNames}
              subcraft={subcraft}
              submaterial={submaterial}
              craftOther={craftOther}
              materialOther={materialOther}

              handlesubcaraft={handlesubcaraft}
              handlesubmaterial={handlesubmaterial}

            /> : ""}

            {activeStep === 2 ? <Formtwo
              next={handleNext}
              back={handleBack}
              arisandata={artisanformData}
              handleChange={handleChange}

            /> : ""}
            {activeStep === 3 ? <Formthree

              back={handleBack}
              arisandata={artisanformData}
              handleChange={handleChange}
              handleChangecivildemo={handleChangecivildemo}
              handleChangerecognition={handleChangerecognition}
              handleChangeyears={handleChangeyears}
              handleCheckboxStore={handleCheckboxStore}
              // b2B_formthree={b2B_formthree}
              setB2B_formthree={setB2B_formthree}
              b2c_formthree={b2c_formthree}
              setB2c_Formthree={setB2c_Formthree}
              ecommerce={ecommerce}
              setEcommerce={setEcommerce}
              export_fthree={export_fthree}
              setExport_Fthree={setExport_Fthree}
              retail={retail}
              setRetail={setRetail}
              Socialmedia={Socialmedia}
              setSocialMedia={setSocialMedia}
              localmarket={localmarket}
              setLocalMarket={setLocalMarket}
              other_fthree={other_fthree}
              setOther_Fthree={setOther_Fthree}
              national={national}
              b2B_formthree={b2B_formthree}
              setNational={setNational}
              stateaward={stateaward}
              setstateaward={setstateaward}
              otheraward_fthree={otheraward_fthree}
              setOtherAward_Fthree={setOtherAward_Fthree}
              notapplie={notapplie}
              setnotapplie={setnotapplie}
              zerotofive={zerotofive}
              setZerotoFive={setZerotoFive}
              fivetoten={fivetoten}
              setFivetoTen={setFivetoTen}
              tentofifteen={tentofifteen}
              settentoFifteen={settentoFifteen}
              submitArtisanForm={submitArtisanForm}
              anyrecognition={anyrecognition}
              setAnyRecognition={setAnyRecognition}
              areumaster={areumaster}
              setAreuMaster={setAreuMaster}
              estore={estore}
              setestore={setEstore}
              firstcheck={firstcheck}
              setfirstcheck={setfirstcheck}
              secondcheck={secondcheck}
              threecheck={threecheck}
              agree={ID ? true : agree}
              setagree={ID ? true : setagree}
              languageval={languageval}
              setLanguageval={setLanguageval}
              captacha={captacha}
              setcaptacha={setcaptacha}
              handleCaptachaChange={handleCaptachaChange}

            /> : ""}
          </div>
        </>
      )}
      <Footer />
    </div>
  )
}

export default ArtisanForm