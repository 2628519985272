import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import Footer from '../Footer/footer';
import Images from '../Images/Imagespic';
import './Opportunitiessummary.css';
import { apiURL } from "../Commen/apiurl"
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { BiWorld } from 'react-icons/bi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Individual_Header from '../Header/new_header';
import SocialOpportunityShareModal from '../New/OpportunitySocialShare';
import ReactLoading from "react-loading";

function Opportunitiessummary() {

    const { opportunityid } = useParams();
    let opportunity_id = window.atob(opportunityid);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [Opportunitity, setOpportunitity] = useState('')
    // console.log(Opportunitity, "Opportunitity")
    const [image, setimage] = useState()
    const [banerimage, setbanerimage] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const openShareModal = () => {
        setIsShareModalOpen(true);
    };
    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };
    useEffect(() => {
        fetchArtisanData(opportunity_id);
        window.scrollTo(0, 0);
    }, []);
    const descriptionHtml = Opportunitity.job_description;
    const fetchArtisanData = (id) => {
        setIsLoading(true);
        axios.get(`${apiURL}/UserMaster/opportunity_view_get_id?id=${id}`)
            .then(response => {
                setOpportunitity(response.data.data[0]);
                setimage(JSON.parse(response.data.data[0]['company_logo']))
                setbanerimage(JSON.parse(response.data.data[0]['banner_image']))
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching artisan data:", error);
                setIsLoading(false);
            });
    };
    const formatDateToMonthDateYear = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    };

    return (
        <div className='opportunitypagecss'>
            <Individual_Header />
            <div className='opp_height'>
                {isLoading ? (
                    <div className='d-flex justify-content-center mt-5'>
                        <ReactLoading type="spin" color="#134f5c" height={100} width={50} />
                    </div>
                ) : (
                    <>
                        <div className='d-flex main-content-section'>
                            <div className='col-xl-2 col-md-2 col-lg-2 left-section'></div>
                            <div className='col-xl-8 col-md-8 col-lg-8 right-section '>
                                <div class="cd_breadcrumbs_container px-2 mb-5 mt-5  ">
                                    <span>
                                        <span>
                                            <a href="https://www.creativedignity.org/">Home</a>
                                        </span> &gt; <span>
                                            <a href="/opportunities">Opportunities </a></span>
                                        &gt; <span><a>{Opportunitity.tittle}</a></span>
                                    </span>
                                </div>
                                {image ?
                                    <img src={`data:image/png;base64,${image.base64Data}`} alt="Base64 Image" className='imglog opper-individual-img mt-5 mb-5' /> :
                                    <img src={Images.Logooppor} className='imglog mt-5 mb-5'></img>
                                }
                                <h3 className='event-posted-text'>Posted on {formatDateToMonthDateYear(Opportunitity.created_at)}</h3>
                                <h1 className='event-junior-tarasha-text hongkong-font  ' style={{ marginLeft: '0px !important' }}>{Opportunitity.tittle}</h1>
                                <div className='row'>
                                    <div className=' col-md-12 mt-4 ' style={{
                                        whiteSpace: "normal", paddingLeft: "0px"
                                    }}>
                                        {Opportunitity && Opportunitity.Opportunity_type ? (
                                            <>
                                                {Opportunitity.Opportunity_type !== "+" ?
                                                    <button variant="secondary" className='event-buttons click_tag mx-2 tag-mob' style={{
                                                        whiteSpace: "normal !important",
                                                        marginBottom: "5px !important"
                                                    }}>
                                                        <span className='spanlist tag-mob' style={{ color: '#fff' }}>
                                                            {Opportunitity.Opportunity_type.split('+')[0]}
                                                        </span>
                                                    </button>
                                                    : null}
                                            </>
                                        ) : null}

                                        {/* {Opportunitity && Opportunitity.tags ? (
                                            <>
                                                {Opportunitity.tags.split(',').map((tag, index) => (
                                                    <button
                                                        key={index}
                                                        variant="secondary"
                                                        className='event-buttons non_click_tag tag-mob mx-2'style={{ whiteSpace: "normal" }}

                                                    >
                                                        <span className='spanlist tag-mob' style={{ color: '#000000'}}>{tag}</span>
                                                    </button>
                                                ))}
                                            </>
                                        ) : null} */}
                                        {Opportunitity && Opportunitity.tags && Opportunitity.tags.trim() !== '' ? (
                                            <>
                                                {Opportunitity.tags.split(',').map((tag, index) => {
                                                    const trimmedTag = tag.trim();
                                                    if (trimmedTag !== '' && trimmedTag !== ',') {
                                                        return (
                                                            <button
                                                                key={index}
                                                                variant="secondary"
                                                                className='event-buttons non_click_tag tag-mob mx-2 mb-3'
                                                                style={{ whiteSpace: "normal" }}
                                                            >
                                                                <span className='spanlist tag-mob' style={{ color: '#000000' }}>{trimmedTag}</span>
                                                            </button>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                        ) : null}
                                        {Opportunitity && Opportunitity.employment_type && Opportunitity.employment_type.trim() !== '' && Opportunitity.employment_type !== '""' ? (
                                            <>
                                                {JSON.parse(Opportunitity.employment_type).map((tag, index) => {
                                                    const trimmedTag = tag.trim();
                                                    if (trimmedTag !== '') {
                                                        return (
                                                            <button
                                                                key={index}
                                                                variant="secondary"
                                                                className='event-buttons non_click_tag tag-mob mx-2 mb-3'
                                                                style={{ whiteSpace: "normal" }}
                                                            >
                                                                <span className='spanlist tag-mob' style={{ color: '#000000' }}>{trimmedTag}</span>
                                                            </button>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </>
                                        ) : null}



                                    </div>
                                </div>

                                {/* <div className='row mt-2 mb-4'>
                                    <div style={{ display: 'flex' }}>
                                        {Opportunitity.opportunity_mode === "online" && (
                                            <div>
                                                <div className='d-flex'>
                                                    <FaMapMarkerAlt className='map-opprtunity mt-4' />
                                                    <p className='location-opportuniy '>Online</p>
                                                </div>
                                            </div>
                                        )}
                                        {Opportunitity.opportunity_mode === "offline" && Opportunitity.venue && (
                                            <div>
                                                <div className='d-flex justify-content-start'>
                                                    <FaMapMarkerAlt className='map-opprtunity mt-4' />
                                                    <p className='location-opportuniy'>Offline, {Opportunitity.venue}</p>
                                                </div>
                                            </div>
                                        )}
                                        {Opportunitity.opportunity_mode === "hybrid" && (
                                            <div>
                                                {Opportunitity.venue && (
                                                    <div className='d-flex '>
                                                        <FaMapMarkerAlt className='map-opprtunity mt-4' />
                                                        <p className='location-opportuniy' >Hybrid, {Opportunitity.venue}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div> */}
                                <div style={{ display: 'flex' }}>
                                    {Opportunitity.opportunity_mode === "online" && (
                                        <>
                                            <div className='mt-1'>
                                                <FaMapMarkerAlt className='heightworld-url ' />
                                            </div>
                                            <div className='pl-4'>
                                                <p style={{ fontSize: '15px' }}>Online, {Opportunitity.venue}</p>
                                            </div>
                                        </>
                                    )}
                                    {Opportunitity.opportunity_mode === "Offline" && (
                                        <>
                                            <div className='mt-1'>
                                                <FaMapMarkerAlt className='heightworld-url ' />
                                            </div>
                                            <div className='pl-4'>
                                                <p style={{ fontSize: '15px' }}>Offline, {Opportunitity.venue}</p>
                                            </div>
                                        </>
                                    )}
                                    {Opportunitity.opportunity_mode === "hybrid" && (
                                        <>
                                            <div className='mt-1'>
                                                <FaMapMarkerAlt className='heightworld-url ' />
                                            </div>
                                            <div className='pl-4'>
                                                <p style={{ fontSize: '15px' }}>hybrid, {Opportunitity.venue}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/* <div class="row  mb-4 ">
                                    <div className='col-xl-12 col-md-12 col-lg-12 url-view d-flex'>
                                        {Opportunitity.instagram_url && (
                                            <>
                                                <BiWorld className='heightworld-url-oppo ' />
                                                <a className='url-mobile mb-3 col-10 mt-1' href={Opportunitity.instagram_url} target="_blank" rel="noopener noreferrer">
                                                    <span className='url-word'>   {Opportunitity.instagram_url}</span>
                                                </a>
                                            </>
                                        )}
                                    </div>
                                </div> */}
                                <div style={{ display: 'flex' }}>
                                    {Opportunitity.instagram_url && (
                                        <>
                                            <div className='mt-1'>
                                                <BiWorld className='heightworld-url ' />
                                            </div>
                                            <div className='pl-4'>
                                                <p style={{ fontSize: '15px' }}>
                                                    <a className='' href={Opportunitity.instagram_url} style={{ textDecoration: 'none', overflowWrap: 'anywhere' }} target="_blank" rel="noopener noreferrer">
                                                        <span className='location-added'>{Opportunitity.instagram_url}</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <h3 className='event-posted-text mb-3'>Application ends on {formatDateToMonthDateYear(Opportunitity.closing_date)}</h3>
                                <div className='row'>
                                    <div className='col-12 col-md-2'>
                                        <Button className='btnshare btn-lg'
                                            onClick={openShareModal}>Share</Button>
                                    </div>
                                    <div className='mt-5'>
                                        <div className='individual-para' dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                                    </div>
                                    <div className='col-12 mt-5 mb-5'>
                                        {banerimage ?
                                            <img src={`data:banerimage/png;base64,${banerimage.base64Data}`} alt="Base64 Image" /> :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Footer></Footer>
            <>
                <SocialOpportunityShareModal
                    isOpen={isShareModalOpen}
                    onClose={closeShareModal}
                    shareUrl={`https://app.creativedignity.org/Opportunitiessummary/${opportunityid}`}
                    title={Opportunitity.tittle}
                    location={Opportunitity.venue}
                    closingDate={Opportunitity.closing_date}
                    Opportunity_id={opportunityid}
                />
            </>
        </div>
    )
}

export default Opportunitiessummary;